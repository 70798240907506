var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
        [
          _c("c-table", {
            ref: "typegrid",
            attrs: {
              title: "기계분류",
              tableId: "typegrid",
              columns: _vm.typegrid.columns,
              data: _vm.typegrid.data,
              usePaging: false,
              hideBottom: true,
              columnSetting: false,
              isFullScreen: false,
              filtering: false,
              isExcelDown: false,
            },
            on: { rowClick: _vm.rowClick },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "기계분류별 안전검사 검사대상/필수항목" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    staticStyle: { "margin-top": "-10px !important" },
                  },
                  [
                    _c(
                      "c-table",
                      {
                        ref: "grid1",
                        attrs: {
                          title: "검사대상",
                          topBorderClass: "topcolor-lightblue",
                          tableId: "grid1",
                          columns: _vm.grid1.columns,
                          data: _vm.grid1.data,
                          editable: _vm.editable,
                          usePaging: false,
                          hideBottom: true,
                          filtering: false,
                          columnSetting: false,
                          isExcelDown: false,
                          isFullScreen: false,
                          selection: "multiple",
                          rowKey: "hazardousMachineryInspectionTargetId",
                        },
                      },
                      [
                        _c(
                          "template",
                          { slot: "table-button" },
                          [
                            _c(
                              "q-btn-group",
                              { attrs: { outline: "" } },
                              [
                                _vm.editable && _vm.isSelectGrp
                                  ? _c("c-btn", {
                                      attrs: { label: "", icon: "add" },
                                      on: { btnClicked: _vm.addRow1 },
                                    })
                                  : _vm._e(),
                                _vm.editable && _vm.isSelectGrp
                                  ? _c("c-btn", {
                                      attrs: { label: "", icon: "save" },
                                      on: { btnClicked: _vm.saveRow1 },
                                    })
                                  : _vm._e(),
                                _vm.editable && _vm.isSelectGrp
                                  ? _c("c-btn", {
                                      attrs: { label: "", icon: "remove" },
                                      on: { btnClicked: _vm.removeRow1 },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9",
                    staticStyle: { "margin-top": "-10px !important" },
                  },
                  [
                    _c(
                      "c-table",
                      {
                        ref: "grid2",
                        attrs: {
                          title: "필수항목",
                          topBorderClass: "topcolor-lightblue",
                          tableId: "grid2",
                          columns: _vm.grid2.columns,
                          data: _vm.grid2.data,
                          editable: _vm.editable,
                          usePaging: false,
                          hideBottom: true,
                          filtering: false,
                          isFullScreen: false,
                          columnSetting: false,
                          isExcelDown: false,
                          selection: "multiple",
                          rowKey: "hazardousMachineryInspectionItemId",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "suffixTitle",
                            fn: function () {
                              return [
                                _vm.editable && _vm.isSelectGrp
                                  ? _c(
                                      "font",
                                      {
                                        staticStyle: {
                                          "font-size": "0.8em",
                                          "font-weight": "300",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "    V : 육안검사    E : 장비검사    A : 작동검사    C : 인증확인대상    "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c(
                          "template",
                          { slot: "table-button" },
                          [
                            _c(
                              "q-btn-group",
                              { attrs: { outline: "" } },
                              [
                                _vm.editable && _vm.isSelectGrp
                                  ? _c("c-btn", {
                                      attrs: { label: "추가", icon: "add" },
                                      on: { btnClicked: _vm.addRow2 },
                                    })
                                  : _vm._e(),
                                _vm.editable && _vm.isSelectGrp
                                  ? _c("c-btn", {
                                      attrs: { label: "삭제", icon: "remove" },
                                      on: { btnClicked: _vm.removeRow2 },
                                    })
                                  : _vm._e(),
                                _vm.editable && _vm.isSelectGrp
                                  ? _c("c-btn", {
                                      attrs: { label: "저장", icon: "save" },
                                      on: { btnClicked: _vm.saveRow2 },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }