<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
        <c-table
          ref="typegrid"
          title="기계분류"
          tableId="typegrid"
          :columns="typegrid.columns"
          :data="typegrid.data"
          @rowClick="rowClick"
          :usePaging="false"
          :hideBottom="true"
          :columnSetting="false"
          :isFullScreen="false"
          :filtering="false"
          :isExcelDown="false"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
        <c-card title="기계분류별 안전검사 검사대상/필수항목" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" style="margin-top: -10px !important;">
              <c-table
                ref="grid1"
                title="검사대상"
                topBorderClass="topcolor-lightblue"
                tableId="grid1"
                :columns="grid1.columns"
                :data="grid1.data"
                :editable="editable"
                :usePaging="false"
                :hideBottom="true"
                :filtering="false"
                :columnSetting="false"
                :isExcelDown="false"
                :isFullScreen="false"
                selection="multiple"
                rowKey="hazardousMachineryInspectionTargetId"
              >
                <template slot="table-button">
                  <q-btn-group outline >
                    <c-btn label="" icon="add" @btnClicked="addRow1" v-if="editable && isSelectGrp" />
                    <c-btn label="" icon="save" @btnClicked="saveRow1" v-if="editable && isSelectGrp" />
                    <c-btn label="" icon="remove" @btnClicked="removeRow1" v-if="editable && isSelectGrp" />
                  </q-btn-group>
                </template>
              </c-table>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9" style="margin-top: -10px !important;">
              <c-table
                ref="grid2"
                title="필수항목"
                topBorderClass="topcolor-lightblue"
                tableId="grid2"
                :columns="grid2.columns"
                :data="grid2.data"
                :editable="editable"
                :usePaging="false"
                :hideBottom="true"
                :filtering="false"
                :isFullScreen="false"
                :columnSetting="false"
                :isExcelDown="false"
                selection="multiple"
                rowKey="hazardousMachineryInspectionItemId"
              >
                <template v-slot:suffixTitle>
                  <font v-if="editable && isSelectGrp" style="font-size:0.8em;font-weight:300;">
                    &nbsp;&nbsp;
                    V : 육안검사 &nbsp;&nbsp;
                    E : 장비검사 &nbsp;&nbsp;
                    A : 작동검사 &nbsp;&nbsp;
                    C : 인증확인대상 &nbsp;&nbsp;
                  </font>
                </template>
                <template slot="table-button">
                  <q-btn-group outline >
                    <c-btn label="추가" icon="add" @btnClicked="addRow2" v-if="editable && isSelectGrp" />
                    <c-btn label="삭제" icon="remove" @btnClicked="removeRow2" v-if="editable && isSelectGrp" />
                    <c-btn label="저장" icon="save" @btnClicked="saveRow2" v-if="editable && isSelectGrp" />
                  </q-btn-group>
                </template>
              </c-table>
            </div>
          </template>
        </c-card>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'vendor-Assess-item',
  data() {
    return {
      editable: true,
      searchParam: {
        plantCd: null,
      },
      isSelectGrp: false,
      selectedGroupCd: '',
      list1Url: '',
      save1Url: '',
      delete1Url: '',
      list2Url: '',
      save2Url: '',
      delete2Url: '',
      typegrid: {
        columns: [
          {
            name: 'codeName',
            field: 'codeName',
            label: '기계분류',
            align: 'left',
            sortable: false,
          }
        ],
        data: [],
      },
      grid1: {
        columns: [
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '조항',
            align: 'center',
            type: 'number',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'itemName',
            field: 'itemName',
            label: '검사항목',
            align: 'center',
            type: 'text',
            sortable: false,
          },
        ],
        data: [],
      },
      grid2: {
        columns: [],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.list1Url = selectConfig.sop.hhm.hazard.inspection.target.list.url;
      this.save1Url = transactionConfig.sop.hhm.hazard.inspection.target.save.url;
      this.delete1Url = transactionConfig.sop.hhm.hazard.inspection.target.delete.url;
      this.list2Url = selectConfig.sop.hhm.hazard.inspection.item.list.url;
      this.save2Url = transactionConfig.sop.hhm.hazard.inspection.item.save.url;
      this.delete2Url = transactionConfig.sop.hhm.hazard.inspection.item.delete.url;
      this.getMothod();
      this.getTypeList();
    },
    getMothod() {
      this.$comm.getComboItems('HHM_TEST_METHOD').then(_result => {
        this.grid2.columns = [
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '번호',
            align: 'center',
            type: 'number',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'itemName',
            field: 'itemName',
            label: '검사항목',
            align: 'center',
            type: 'text',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'itemCriterion',
            field: 'itemCriterion',
            label: '판정기준',
            align: 'left',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'testMothod',
            field: 'testMothod',
            label: '검사방법',
            align: 'center',
            style: 'width:100px',
            type: 'multiSelect',
            itemText: 'codeName',
            itemValue: 'code',
            comboItems: _result,
            sortable: false,
          },
          {
            name: 'useEquip',
            field: 'useEquip',
            label: '활용장비',
            align: 'center',
            type: 'text',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'article',
            field: 'article',
            label: '조항',
            align: 'center',
            type: 'text',
            style: 'width:80px',
            sortable: false,
          },
        ]
      });
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1');
      if (_table.length > 0) {
        for(let c = 0; c <= _table.length; c++) {
          _table[c].classList.remove('bg-light-blue-1');
        }
      }
      this.selectedGroupCd = '';
    },
    rowClick(row) {
      this.isSelectGrp = true;
      this.selectedGroupCd = row.code;
      this.getList1(row);
      this.getList2(row);
    },
    getTypeList() {
      this.rowRemoveSelect();
      this.isSelectGrp = false;
      this.grid1.data = [];
      this.grid2.data = [];
      this.$comm.getComboItems('HHM_TYPE_CD').then(_result => {
        this.typegrid.data = _result;
      });
    },
    getList1(row) {
      this.$http.url = this.list1Url;
      this.$http.type = 'GET';
      this.$http.param = {
        machineryClassification: row.code,
      }
      this.$http.request((_result) => {
        this.grid1.data = _result.data;
      },
      () => {
      });
    },
    addRow1() {
      this.grid1.data.push({
        editFlag: 'C',
        hazardousMachineryInspectionTargetId: uid(),  // 검사대상-검사항목 일련번호
        machineryClassification: this.selectedGroupCd,  // 기계분류
        itemName: '',  // 검사대상-검사항목명
        sortOrder: this.grid1.data.length === 0 ? 1 : parseInt(this.grid1.data[this.grid1.data.length-1].sortOrder) + 1,  // 순번
      })
    },
    saveRow1() {
      let checkItem = ['sortOrder', 'itemName']
      let isConti = true;
      this.$_.forEach(this.grid1.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [조항, 검사항목]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.grid1.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.save1Url;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getList1({code: saveData[0].machineryClassification});
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    removeRow1() {
      let selectData = this.$refs['grid1'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.delete1Url);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getItemList({code: selectData[0].codeGrpCd});
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid1.data = this.$_.reject(this.grid1.data, item);
              })
              this.$refs['grid1'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    getList2(row) {
      this.$http.url = this.list2Url;
      this.$http.type = 'GET';
      this.$http.param = {
        machineryClassification: row.code,
      }
      this.$http.request((_result) => {
        this.grid2.data = _result.data;
      },
      () => {
      });
    },
    addRow2() {
      this.grid2.data.push({
        editFlag: 'C',
        hazardousMachineryInspectionItemId: uid(),  // 검사항목 일련번호
        machineryClassification: this.selectedGroupCd,  // 기계분류
        itemName: '',  // 검사항목명
        itemCriterion: '',  // 판정기준
        testMothod: '',  // 검사방법
        useEquip: '',  // 활용장비
        article: '',  // 조항
        sortOrder: this.grid2.data.length + 1,  // 순번
      })
    },
    saveRow2() {
      let checkItem = ['sortOrder', 'itemName', 'itemCriterion']
      let isConti = true;
      this.$_.forEach(this.grid2.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [순번, 검사항목, 판정기준]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.grid2.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.save2Url;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getList2({code: saveData[0].machineryClassification});
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    removeRow2() {
      let selectData = this.$refs['grid2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.delete2Url);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getItemList({code: selectData[0].codeGrpCd});
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, item);
              })
              this.$refs['grid2'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
